// move the checkbox down compared to the label
.el-checkbox__input {
    margin-top: 2px;
}

// change the label
.el-checkbox__label {
    font-size: 0.95rem;
    font-weight: normal;
}

// allow hidden labels
.el-checkbox.is-labeless .el-checkbox__label {
    display: none;
}

// change the hover label color
.el-checkbox:hover {
    color: $grey-darker;
    transition: color 0.2 ease;
}

// change the box color on hover
.el-checkbox:hover .el-checkbox__inner {
    background-color: lighten($border, 10%);
}

// make the default checkbox bigger
.el-checkbox__inner {
    width: 24px;
    height: 24px;
    border-width: 2px;
    border-color: darken($border, 5%);
    transition: none;
    transition: background 0.15 ease;
}

// change the css arrow to font awesome
.el-checkbox__inner::after {
    @include fa;
    border: 0;
    transform: none;
    content: "\f00c";
    color: #ffffff;
    font-size: 14px;
    opacity: 0;
    left: 3px;
    top: 3px;
    transition: opacity .15s ease-out;
}

.el-checkbox__input.is-checked .el-checkbox__inner::after {
    transform: none !important;
    opacity: 1;
}

// change the hover checkbox color
.el-checkbox__inner:hover,
.el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: $border;
    background-color: lighten($border, 10%);
}

// change the active label color
.el-checkbox__input.is-checked + .el-checkbox__label {
    color: $black-ter;
}

// change the active bg color
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: $primary;
    border-color: $primary;
}

